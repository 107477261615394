/* screen settings */
html, body { height: 100%; min-height: 100%; min-width: 320px;  }
.screen { display: block; height: 100%; width: 100%; overflow-x: hidden; }
#page--core > *, .page--sub { min-width: 320px; }
.page { display: flex; width: 100%; height: 100%; min-height: 100%;}
header { padding: .625rem 1.25rem; height: 3rem; width: 100%; display: flex; align-items: center; background: var(--primaryColor); position: fixed; color: #fff; cursor: default;}

/* layout  */
.container { width: 100%; height: 100%; padding: 1.25rem 1rem; max-width: 800px; margin: 0 auto; }
.surface { background: #fff; top: 0; bottom: 0; height: 100%; }
.container { display: flex; flex-direction: column; flex-shrink: 0; }
.container > * { flex-shrink: 0; }
.page--form {
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin-top: 50px;
}
hr {
  display: block;
  width: 100%;
  margin: 1rem 0 1.5rem;
  border: 0;
  border-top: 1px solid #bbb;
}

/* unsubscribe */
.secure-logo { display: block; width: 100px; height: 77px; margin: 2rem auto; background-image: url('./assets/secure.png'); }
.form-message { min-height: 75px; text-align: center; margin-bottom: 20px; }
.logo {
  height: 109px;
  margin: 0 auto 3rem;
}
.copyright { padding: 0 0 1rem;  width: 100%; }
.disclaimer, .subtext { font-size: .75rem; }
.subtext { color: #727272; text-align: center; }
.btm { margin: auto 1rem 1rem;}
